<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <h2 class="mr-4">Vehicle Checklist</h2>
            <v-btn color="primary" @click="showCreateForm = true">Create Checklist</v-btn>
          </div>

          <!-- Dialog for Creating Checklist -->
          <v-dialog v-model="showCreateForm" max-width="600">
            <v-card>
              <v-card-title>Create Checklist</v-card-title>
              <v-card-text>
                <!-- Vehicle and Trip Selection -->
                <v-autocomplete
                  v-model="selectedVehicle"
                  :items="vehicles"
                  label="Select Vehicle"
                  item-text="vehicle_name"
                  item-value="id"
                  clearable
                  outlined
                  :error-messages="vehicleErrors"
                  @input="vehicleErrors = []"
                  required
                ></v-autocomplete>
                <v-autocomplete
                  v-model="selectedTrip"
                  :items="trips"
                  label="Select Trip"
                  item-text="name"
                  item-value="id"
                  clearable
                  outlined
                  :error-messages="tripErrors"
                  @input="tripErrors = []"
                  required
                ></v-autocomplete>

                <!-- Form for Mileage, Fuel Level, Condition, and Pictures -->
                <v-text-field
                  v-model="mileage"
                  label="Mileage"
                  outlined
                  :error-messages="mileageError"
                  @input="mileageError = ''"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="fuelLevel"
                  label="Fuel Level"
                  outlined
                  :error-messages="fuelLevelError"
                  @input="fuelLevelError = ''"
                  required
                ></v-text-field>
                <v-textarea
                  v-model="condition"
                  label="Condition"
                  outlined
                  :error-messages="conditionError"
                  @input="conditionError = ''"
                  required
                ></v-textarea>

                <!-- File Input for Pictures -->
                <v-file-input
                  v-model="pictures"
                  label="Upload or Capture Pictures"
                  accept="image/*;capture=camera"
                  multiple
                  outlined
                  :error-messages="picturesError"
                  @change="picturesError = ''"
                ></v-file-input>
              </v-card-text>
              <v-card-actions>
                <v-btn color="blue darken-1" @click="validateAndCreateChecklist">Create</v-btn>
                <v-btn color="grey" @click="resetForm">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Data Table for Displaying Checklists -->
          <v-data-table
            :headers="headers"
            :items="checklists"
            item-key="id"
            :search="search"
            :loading="isLoading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.name="{ item }">{{ item.trip.name }}</template>
            <template v-slot:item.created_at="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.vehicle_name="{ item }">{{ item.vehicle.vehicle_name }}</template>
            <template v-slot:item.mileage="{ item }">{{ item.mileage }}</template>
            <template v-slot:item.fuel_level="{ item }">{{ item.fuel_level }}</template>
            <template v-slot:item.condition="{ item }">{{ item.condition }}</template>

            <!-- New Actions Column -->
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="viewChecklist(item)">mdi-eye</v-icon>
              <v-icon small @click="confirmDelete(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <!-- Confirmation Dialog for Deleting Checklist -->
    <v-dialog v-model="showDeleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text>Are you sure you want to delete this checklist?</v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="deleteChecklist">Yes, Delete</v-btn>
          <v-btn color="grey" @click="showDeleteDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Image Preview Dialog -->
    <v-dialog v-model="showImagePreview" max-width="800">
      <v-card>
        <v-card-title>
          <v-icon color="primary">mdi-file-image</v-icon>
          <span class="pl-2">Checklist Images</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <!-- Loop through images and display each one -->
            <v-col
              v-for="(image, index) in images"
              :key="index"
              cols="12" md="4" lg="3"
            >
              <div class="checklist-image">
                <v-avatar
                  rounded
                  size="120"
                  @click="viewImage(image)"
                >
                  <v-img
                    :src="getImageUrl(image)"
                    alt="Checklist Image"
                  ></v-img>
                </v-avatar>
              </div>
              <p class="font-weight-bold mt-2">Image {{ index + 1 }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" @click="showImagePreview = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      vehicles: [],
      trips: [],
      selectedVehicle: null,
      selectedTrip: null,
      mileage: '',
      fuelLevel: '',
      condition: '',
      pictures: [],
      vehicleErrors: [],
      tripErrors: [],
      mileageError: '',
      fuelLevelError: '',
      conditionError: '',
      picturesError: '',
      checklists: [],
      isLoading: false,
      search: "",
      headers: [
        { text: "Trip Name", value: "name" },
        { text: "CheckIn Date", value: "created_at" },
        { text: "Vehicle Name", value: "vehicle_name" },
        { text: "Mileage", value: "mileage" },
        { text: "Fuel Level", value: "fuel_level" },
        { text: "Condition", value: "condition" },
        { text: "Actions", value: "actions", sortable: false }, // Add new column header
      ],
      showCreateForm: false,
      showDeleteDialog: false,
      showImagePreview: false,
      checklistToDelete: null, // To store the checklist to delete
      images: [], // List of images for the selected checklist
      selectedChecklist: null, // Selected checklist for viewing images
    };
  },
  mounted() {
    this.loadChecklists();
    this.loadVehicles();
    this.loadTrips();
  },
  methods: {
    loadChecklists() {
      this.isLoading = true;
      axios
        .get(`/vehicle-checklists/all`)
        .then((response) => {
          this.checklists = response.data;
        })
        .catch((error) => {
          console.error('Error loading checklists:', error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadVehicles() {
      axios
        .get(`/buses/all`)
        .then((response) => {
          this.vehicles = response.data;
        })
        .catch((error) => {
          console.error('Error loading vehicles:', error);
        });
    },
    loadTrips() {
      axios
        .get(`/vehicle-checklists/trips`)
        .then((response) => {
          this.trips = response.data.activeTrips;
        })
        .catch((error) => {
          console.error('Error loading trips:', error);
        });
    },
    validateAndCreateChecklist() {
      let valid = true;

      if (!this.selectedVehicle) {
        this.vehicleErrors.push('Please select a vehicle');
        valid = false;
      }
      if (!this.selectedTrip) {
        this.tripErrors.push('Please select a trip');
        valid = false;
      }
      if (!this.mileage) {
        this.mileageError = 'Please enter mileage';
        valid = false;
      }
      if (!this.fuelLevel) {
        this.fuelLevelError = 'Please enter fuel level';
        valid = false;
      }
      if (!this.condition) {
        this.conditionError = 'Please enter condition';
        valid = false;
      }
      if (this.pictures.length === 0) {
        this.picturesError = 'Please upload or capture at least one picture';
        valid = false;
      }

      if (valid) {
        this.createChecklist();
      }
    },
    createChecklist() {
      const formData = new FormData();
      formData.append('trip_id', this.selectedTrip);
      formData.append('vehicle_id', this.selectedVehicle);
      formData.append('mileage', this.mileage);
      formData.append('fuel_level', this.fuelLevel);
      formData.append('condition', this.condition);

      // Append each picture file to the FormData
      this.pictures.forEach((picture, index) => {
        formData.append(`pictures[${index}]`, picture);
      });

      axios.post('/vehicle-checklists/create-edit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        this.loadChecklists();
        this.resetForm();
        this.showCreateForm = false;
      })
      .catch((error) => {
        console.error('Error creating checklist:', error);
      });
    },
    resetForm() {
      this.selectedVehicle = null;
      this.selectedTrip = null;
      this.mileage = '';
      this.fuelLevel = '';
      this.condition = '';
      this.pictures = [];
      this.vehicleErrors = [];
      this.tripErrors = [];
      this.mileageError = '';
      this.fuelLevelError = '';
      this.conditionError = '';
      this.picturesError = '';
    },
    confirmDelete(checklist) {
      this.checklistToDelete = checklist;
      this.showDeleteDialog = true;
    },
    getImageUrl(image) {
    // Use remote file path if available, otherwise fallback to local file path
    const path = image.remote_file_path;
    return `${process.env.VUE_APP_API_URL}/${path}`;
  },

  viewImage(image) {
    console.log('Viewing image:', image);
  },
    deleteChecklist() {
      if (this.checklistToDelete) {
        axios
        .post(`/vehicle-checklists/delete`, { id: this.checklistToDelete.id })
          .then(() => {
            this.loadChecklists();
            this.showDeleteDialog = false;
            this.checklistToDelete = null;
          })
          .catch((error) => {
            console.error('Error deleting checklist:', error);
          });
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString(); // This will include both date and time
    },
    viewChecklist(item) {
      this.selectedChecklist = item;
      axios
        .get(`/vehicle-checklists/images/${item.id}`)
        .then((response) => {
          this.images = response.data.images;
          this.showImagePreview = true;
        })
        .catch((error) => {
          console.error('Error loading checklist images:', error);
        });
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles */
</style>
